import _useSpeechRecognition2 from "./useSpeechRecognition";
import _useSpeechSynthesis2 from "./useSpeechSynthesis";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _useSpeechRecognition = _useSpeechRecognition2;
Object.defineProperty(exports, "useSpeechRecognition", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_useSpeechRecognition).default;
  }
});
var _useSpeechSynthesis = _useSpeechSynthesis2;
Object.defineProperty(exports, "useSpeechSynthesis", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_useSpeechSynthesis).default;
  }
});

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

export default exports;
export const __esModule = exports.__esModule,
      useSpeechRecognition = exports.useSpeechRecognition,
      useSpeechSynthesis = exports.useSpeechSynthesis;